import { IonAvatar, IonIcon, IonCol, IonRow, IonGrid, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonCard, IonCardContent, IonContent, IonFooter, IonTitle, IonInput, IonItem, IonList, IonText } from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import { io } from 'socket.io-client';
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isOnline } from "../utils/online";
import { getValidAccessToken } from '../utils/oidc';
export default defineComponent({
    props: {
        appId: String,
    },
    components: {
        IonAvatar,
        IonButton,
        IonButtons,
        IonCard,
        IonCardContent,
        IonCol,
        IonContent,
        IonFooter,
        IonGrid,
        IonHeader,
        IonIcon,
        IonInput,
        IonItem,
        IonList,
        IonPage,
        IonRow,
        IonText,
        IonTitle,
        IonToolbar,
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const chatMessages = ref([]);
        const ionContentRef = ref(null);
        let socket;
        onMounted(async () => {
            socket = io('https://my.living-apps.de', {
                path: '/social/socket.io',
                auth: {
                    token: await getValidAccessToken(),
                },
                upgrade: false,
            });
            socket.on('social.app.initial', (data) => {
                console.info(data);
            });
            socket.on('social.app.delayed', (data) => {
                console.info(data);
            });
            setTimeout(() => {
                socket.emit('social.all', { hostname: document.location.hostname, appId: props.appId });
            }, 2000);
            // Development chat data
            chatMessages.value = [
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world',
                    id: "1"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 2',
                    id: "2"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 3',
                    id: "3"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 4',
                    id: "4"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 5',
                    id: "5"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 6',
                    id: "6"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 7',
                    id: "7"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 8',
                    id: "8"
                },
                {
                    name: 'René',
                    url: 'https://my.living-apps.de/imgproxycache/insecure/rt:fill/w:179/h:179/el:1/g:sm/q:100/fn:23736129_1951291561775455_3736158975388155774_o.jpg/plain/https://my.living-apps.de/gateway/files/5b340580c1ef40f786e2782a',
                    message: 'Hello world 9, this is a multiline text ... Oh my it does not break',
                    id: "9"
                },
            ];
            // scroll to bottom
            setTimeout(() => {
                document.getElementById('ionContentChat').scrollToBottom();
            }, 100);
        });
        return {
            arrowBack,
            canReturn: route.query.back,
            chatMessages,
            ionContentRef,
            isOnline,
            router,
        };
    }
});
